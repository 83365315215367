import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import { mapEdgesToNodes } from "../lib/helpers";

const Page = ({ data }) => {
  const teamMembersNodes = (data || {}).teamMembers
    ? mapEdgesToNodes(data.teamMembers)
    : [];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Our Team | Brierton, Jones & Jones | Trust & Estate Attorney"
        description="At Brierton, Jones & Jones, LLP we believe in using our 30+ years of experience to put the law on your side to help plan for your future. Meet our team here!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-blue pb-20 pt-18 md:pb-32 md:pt-18">
        <div className="container">
          <header className="mb-18 max-w-[720px] md:mb-18">
            <h1 className="text-snow">Meet Our Legal Team</h1>
            <p className="text-gray-50">
            Learn more about the experts on your side.
            </p>
          </header>

          {teamMembersNodes.filter(
            (items) => items.category.title === "Attorneys"
          ).length > 0 && (
            <>
              <header className="mb:mb-12 mb-10">
                <h2 className="heading-three text-snow">Attorneys</h2>
              </header>
              <div className="mb-16 grid grid-cols-2 gap-y-8 gap-x-4 md:mb-26 md:grid-cols-3 md:gap-y-12 md:gap-x-5 lg:grid-cols-5">
                {teamMembersNodes
                  .filter((items) => items.category.title === "Attorneys")
                  .map((item, i) => (
                    <div key={i}>
                      <Link
                        to={`/${item.slug.current}/`}
                        className="group font-normal no-underline"
                      >
                        <div className="mb-4 overflow-hidden">
                          <GatsbyImage
                            image={item.headshot.asset.gatsbyImageData}
                            className="z-0 w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                          />
                        </div>
                        <div className="heading-five mb-1 text-gray-50">
                          {item.name}
                        </div>
                        <div className="mb-0 text-sm text-gray-50">
                          {item.title}
                        </div>
                      </Link>
                    </div>
                  ))}
              </div>
            </>
          )}

          {teamMembersNodes.filter(
            (items) => items.category.title === "Paralegals"
          ).length > 0 && (
            <>
              <header className="mb:mb-12 mb-10">
                <h2 className="heading-three text-snow">Paralegals</h2>
              </header>
              <div className="mb-20 border-t border-gray-300 md:mb-28">
                {teamMembersNodes
                  .filter(
                    (items) => items.category.title === "Paralegals"
                  )
                  .map((item, i) => (
                    <div
                      key={i}
                      className={`flex flex-col border-b border-gray-300 py-5 md:grid md:grid-cols-3 md:py-10 ${
                        item.headshot && "space-x-8 md:space-x-0"
                      }`}
                    >
                      {item.headshot && (
                        <div className="md:hidden">
                          <GatsbyImage
                            image={item.headshot.asset.gatsbyImageData}
                            width={80}
                            className="rounded-full"
                          />
                        </div>
                      )}
                      <div className="mb-5 md:mb-0">
                        {item.headshot && (
                          <div className="hidden md:block">
                            <GatsbyImage
                              image={item.headshot.asset.gatsbyImageData}
                              width={80}
                              className="rounded-full"
                            />
                          </div>
                        )}
                        <div className="font-bold text-gray-50">
                          {item.name}
                        </div>
                      </div>

                      <div className="mb-5 text-sm font-bold uppercase tracking-wide text-gray-50 md:mb-0">
                        {item.title}
                      </div>

                      <div className="font-semibold text-gray-50">
                        <a
                          href={`mailto:${item.emailAddress}`}
                          className="text-gray-50"
                        >
                          {item.emailAddress}
                        </a>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}

          {teamMembersNodes.filter(
            (items) => items.category.title === "Administration"
          ).length > 0 && (
            <>
              <header className="mb:mb-12 mb-10">
                <h2 className="heading-three text-snow">Administration</h2>
              </header>
              <div className="border-t border-gray-300">
                {teamMembersNodes
                  .filter((items) => items.category.title === "Administration")
                  .map((item, i) => (
                    <div
                      key={i}
                      className={`flex flex-col border-b border-gray-300 py-5 md:grid md:grid-cols-3 md:py-10 ${
                        item.headshot && "space-x-8 md:space-x-0"
                      }`}
                    >
                      {item.headshot && (
                        <div className="md:hidden">
                          <GatsbyImage
                            image={item.headshot.asset.gatsbyImageData}
                            width={80}
                            className="rounded-full"
                          />
                        </div>
                      )}
                      <div className="mb-5 md:mb-0">
                        {item.headshot && (
                          <div className="hidden md:block">
                            <GatsbyImage
                              image={item.headshot.asset.gatsbyImageData}
                              width={80}
                              className="rounded-full"
                            />
                          </div>
                        )}
                        <div className="font-bold text-gray-50">
                          {item.name}
                        </div>
                      </div>

                      <div className="mb-5 text-sm font-bold uppercase tracking-wide text-gray-50 md:mb-0">
                        {item.title}
                      </div>

                      <div className="font-semibold text-gray-50">
                        <a
                          href={`mailto:${item.emailAddress}`}
                          className="text-gray-50"
                        >
                          {item.emailAddress}
                        </a>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
    teamMembers: allSanityTeamMember(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          id
          name
          slug {
            current
          }
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 374)
            }
          }
          title
          emailAddress
          category {
            title
          }
        }
      }
    }
  }
`;

export default Page;
